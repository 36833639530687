import React from 'react'

import { Box } from '@chakra-ui/react'

interface Props {
    bg?: string
    hasShadow?: boolean
    shadow?: string
    hasBorderRadius?: boolean
    hasShadowEffect?: boolean
    size?: string | string[]
    children: React.ReactNode
    align?: string
    borderWidth?: string
    p?: string
    zIndex?: number
    overflow?: string
    minH?: string | string[]
    height: string
}

const Card = ({
    bg = 'white',
    hasShadow = true,
    shadow = 'lg',
    hasBorderRadius = true,
    hasShadowEffect = false,
    size = 'sm',
    height,
    children,
    ...rest
}: Props) => (
    <Box
        bg={bg}
        shadow={hasShadow ? shadow : undefined}
        w={size}
        h={height || 'fit-content'}
        p="6"
        borderRadius={hasBorderRadius ? 'xl' : undefined}
        _hover={{ shadow: hasShadowEffect ? 'xl' : undefined, transform: hasShadowEffect ? 'scale(1.02)' : undefined }}
        transition="all ease-in-out 0.2s"
        {...rest}
    >
        {children}
    </Box>
)

export default Card
